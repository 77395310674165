/* eslint-disable */
import './styles.scss'
import React, { useEffect, useState, useContext } from 'react'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { useTranslation } from 'react-i18next'
import { getLocalUserDetails } from 'Utils/LocalStorage_Handler'
import { commonMethods } from 'Utils/commonMethods'
import { CoffeeWebContext } from 'Context/CoffeeWebContext'
import { coffeeReportJsonLinks } from 'Utils/Constants'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'

const CountryCertifiedStocks = ({ selectedMarket, setSelectedMarket, mockDataForCountryCertifiedStock }) => {
  const { t } = useTranslation()
  const { setLoading } = useContext(CoffeeWebContext)
  const { robustaAllCountryCertifiedStocks, arabicaAllCountryCertifiedStocks } = coffeeReportJsonLinks

  const location = useLocation()
  const fullPath = location.pathname
  const pathSegments = fullPath.split('/')
  const desiredPath = pathSegments[2] || ''
  const userDetails = getLocalUserDetails()
  const { checkForUserAccess } = commonMethods

  let menuLock

  if (userDetails && userDetails.menus) {
    const menu = userDetails.menus.find(({ menuUrl }) => menuUrl === desiredPath)

    if (menu) {
      menuLock = menu.menulock
    }
  }

  const [swapData, setSwapData] = useState('Metric Tons')
  const [modifiedData, setModifiedData] = useState([])

  const fetchData = async () => {
    setLoading(true)
    const reportName = selectedMarket === 'Robusta' ? robustaAllCountryCertifiedStocks : arabicaAllCountryCertifiedStocks

    try {
      const response = await apiAdapterCoffeeWeb.getDataByReportName({ reportName })

      if (response.data?.length > 0) {
        transformData(selectedMarket, response.data)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  // useEffect to initialize state for testing purpose
  useEffect(() => {
    if (mockDataForCountryCertifiedStock) {
      setModifiedData(mockDataForCountryCertifiedStock)
    }
  }, [])

  useEffect(() => {
    fetchData()

    if (selectedMarket) {
      setSwapData('Metric Tons')
    }
  }, [selectedMarket])

  const handleMarketNameChange = () => {
    setSelectedMarket(selectedMarket === 'Robusta' ? 'Arabica' : 'Robusta')
    setModifiedData([])
  }

  const toggleSwapOption = () => {
    setSwapData((prevOption) => {
      if (selectedMarket === 'Robusta') {
        return prevOption === 'Metric Tons' ? 'LOTS' : 'Metric Tons'
      } else if (selectedMarket === 'Arabica') {
        return prevOption === 'Metric Tons' ? '60 KG BAGS' : 'Metric Tons'
      }

      return 'Metric Tons'
    })
  }

  const convertMTToBags = (valueInMT) => {
    const result = (valueInMT * 1000) / 60

    return Math.round(result)
  }

  const getTextColor = (value) => {
    if (value === 0) {
      return 'neutral'
    } else if (value > 0) {
      return 'positive'
    }

    return 'negative'
  }

  const determineCellClass = (currentValue, previousValue) => {
    if (currentValue > previousValue) {
      return 'positive'
    } else if (currentValue < previousValue) {
      return 'negative'
    }

    return 'neutral'
  }

  const robustaFlags = [
    {
      port: 'AMS-Amsterdam',
      countryName: 'Netherlands',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/NLD.svg'
    },
    {
      port: 'ANT-Antwerp',
      countryName: 'Belgium',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/BEL.svg'
    },
    {
      port: 'BAR-Barcelona',
      countryName: 'Spain',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/ESP.svg'
    },
    {
      port: 'BRE-Bremen',
      countryName: 'Germany',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/DEU.svg'
    },
    {
      port: 'FEL-Felixstowe',
      countryName: 'England',
      flagsUrl: 'https://upload.wikimedia.org/wikipedia/en/b/be/Flag_of_England.svg'
    },
    {
      port: 'GEN-Genoa',
      countryName: 'Italy',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/ITA.svg'
    },
    {
      port: 'HAM-Hamburg',
      countryName: 'Germany',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/DEU.svg'
    },
    {
      port: 'LEH-Lehavre',
      countryName: 'France',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/FRA.svg'
    },
    {
      port: 'LIV-Liverpool',
      countryName: 'England',
      flagsUrl: 'https://upload.wikimedia.org/wikipedia/en/b/be/Flag_of_England.svg'
    },
    {
      port: 'LON-London',
      countryName: 'England',
      flagsUrl: 'https://upload.wikimedia.org/wikipedia/en/b/be/Flag_of_England.svg'
    },
    {
      port: 'NOR-Norwich',
      countryName: 'England',
      flagsUrl: 'https://upload.wikimedia.org/wikipedia/en/b/be/Flag_of_England.svg'
    },
    {
      port: 'ROT-Rotterdam',
      countryName: 'Netherlands',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/NLD.svg'
    },
    {
      port: 'TRI-Trieste',
      countryName: 'Italy',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/ITA.svg'
    }
  ]

  const arabicaFlags = [
    {
      countryName: 'Brazil',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/BRA.svg'
    },
    {
      countryName: 'Burundi',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/BDI.svg'
    },
    {
      countryName: 'Colombia',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/COL.svg'
    },
    {
      countryName: 'El Salvador',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/SLV.svg'
    },
    {
      countryName: 'Guatemala',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/GTM.svg'
    },
    {
      countryName: 'Honduras',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/HND.svg'
    },
    {
      countryName: 'India',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/IND.svg'
    },
    {
      countryName: 'Kenya',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/KEN.svg'
    },
    {
      countryName: 'Mexico',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/MEX.svg'
    },
    {
      countryName: 'Nicaragua',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/NIC.svg'
    },
    {
      countryName: 'Papua New Guinea',
      flagsUrl: 'https://upload.wikimedia.org/wikipedia/commons/e/e3/Flag_of_Papua_New_Guinea.svg'
    },
    {
      countryName: 'Peru',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/PER.svg'
    },
    {
      countryName: 'Rwanda',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/RWA.svg'
    },
    {
      countryName: 'Tanzania',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/TZA.svg'
    },
    {
      countryName: 'Uganda',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/UGA.svg'
    },
    {
      countryName: 'Venezuela',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/VEN.svg'
    }
  ]

  const arabicaWarehouseColumns = [
    {
      warehouseName: 'ANTWERP_COLUMN',
      countryName: 'Belgium',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/BEL.svg'
    },
    {
      warehouseName: 'BARCELONA_COLUMN',
      countryName: 'Spain',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/ESP.svg'
    },
    {
      warehouseName: 'HAMBURG_BREMEN',
      countryName: 'Germany',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/DEU.svg'
    },
    {
      warehouseName: 'HOUSTON_COLUMN',
      countryName: 'United States',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/USA.svg'
    },
    {
      warehouseName: 'MIAMI_COLUMN',
      countryName: 'United States',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/USA.svg'
    },
    {
      warehouseName: 'NEW_ORLEANS',
      countryName: 'United States',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/USA.svg'
    },
    {
      warehouseName: 'NEW_YORK_COLUMN',
      countryName: 'United States',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/USA.svg'
    },
    {
      warehouseName: 'VIRGINIA_COLUMN',
      countryName: 'United States',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/USA.svg'
    }
  ]

  const transformData = (marketType, data) => {
    const isRobusta = marketType === 'Robusta'
    const updatedData = data?.map((ele, parentIndex) => {
      if (isRobusta) {
        return {
          ...ele,
          grandTotalForValid: ele.countryCertifiedData.reduce((acc, current) => acc + current.valid, 0),
          grandTotalForCompareToPreDay: ele.countryCertifiedData.reduce((acc, current) => acc + current.compareToPreDay, 0),
          grandTotalForNonTenderable: ele.countryCertifiedData.reduce((acc, current) => acc + current.NonTenderable, 0),
          grandTotalForSuspend: ele.countryCertifiedData.reduce((acc, current) => acc + current.suspend, 0),
          countryCertifiedData: ele.countryCertifiedData.map((item, childIndex) => {
            const nextItemValue = data[parentIndex + 1]?.countryCertifiedData[childIndex]
            return {
              ...item,
              validTextColor: determineCellClass(item?.valid, nextItemValue?.valid),
              compareToPreDayTextColor: getTextColor(item?.compareToPreDay),
              flagUrl: robustaFlags[childIndex]?.flagsUrl,
              countryName: robustaFlags[childIndex]?.countryName
            }
          })
        }
      } else {
        return {
          ...ele,
          grandTotalForAntwerp: ele.countryCertifiedData.reduce((acc, current) => acc + current.antwerp, 0),
          grandTotalForBarcelona: ele.countryCertifiedData.reduce((acc, current) => acc + current.barcelona, 0),
          grandTotalForHamburg: ele.countryCertifiedData.reduce((acc, current) => acc + current.hamburg, 0),
          grandTotalForHouston: ele.countryCertifiedData.reduce((acc, current) => acc + current.houston, 0),
          grandTotalForMiami: ele.countryCertifiedData.reduce((acc, current) => acc + current.miami, 0),
          grandTotalForNewOrleans: ele.countryCertifiedData.reduce((acc, current) => acc + current.newOrleans, 0),
          grandTotalForNewYork: ele.countryCertifiedData.reduce((acc, current) => acc + current.newYork, 0),
          grandTotalForVirginia: ele.countryCertifiedData.reduce((acc, current) => acc + current.virginia, 0),
          grandTotalForCompareToPreDay: ele.countryCertifiedData.reduce((acc, current) => acc + current.compareToPreDay, 0),
          countryCertifiedData: ele.countryCertifiedData.map((item, childIndex) => {
            const nextItemValue = data[parentIndex + 1]?.countryCertifiedData[childIndex]

            const findTotal = (item, fields) => {
              let total = 0

              for (let i = 0; i < fields.length; i++) {
                total = total + item?.[fields?.[i]]
              }

              return total
            }

            return {
              ...item,
              antwerpTextColor: determineCellClass(item?.antwerp, nextItemValue?.antwerp),
              barcelonaTextColor: determineCellClass(item?.barcelona, nextItemValue?.barcelona),
              hamburgTextColor: determineCellClass(item?.hamburg, nextItemValue?.hamburg),
              houstonTextColor: determineCellClass(item?.houston, nextItemValue?.houston),
              miamiTextColor: determineCellClass(item?.miami, nextItemValue?.miami),
              newOrleansTextColor: determineCellClass(item?.newOrleans, nextItemValue?.newOrleans),
              newYorkTextColor: determineCellClass(item?.newYork, nextItemValue?.newYork),
              virginiaTextColor: determineCellClass(item?.virginia, nextItemValue?.virginia),
              compareToPreDayTextColor: getTextColor(item.compareToPreDay),
              totalValue: findTotal(item, ['antwerp', 'barcelona', 'hamburg', 'houston', 'miami', 'newOrleans', 'newYork', 'virginia']),
              totalTextColor: determineCellClass(findTotal(item, ['antwerp', 'barcelona', 'hamburg', 'houston', 'miami', 'newOrleans', 'newYork', 'virginia']), findTotal(nextItemValue, ['antwerp', 'barcelona', 'hamburg', 'houston', 'miami', 'newOrleans', 'newYork', 'virginia'])),
              totalValueTextColor: determineCellClass(item?.virginia, nextItemValue?.virginia),
              flagUrl: arabicaFlags[childIndex].flagsUrl
            }
          })
        }
      }
    })

    const updatedFinalArray = updatedData?.map((ele, index) => {
      if (isRobusta) {
        return {
          ...ele,
          grandTotalForValidTextColor: determineCellClass(ele.grandTotalForValid, updatedData[index + 1]?.grandTotalForValid),
          grandTotalForCompareToPreDayTextColor: getTextColor(ele.grandTotalForCompareToPreDay)
        }
      } else {
        const currentGrandTotalForTotal = ele.countryCertifiedData.reduce((acc, current) => acc + current.totalValue, 0)
        const nextGrandTotalForTotalTotal = updatedData[index + 1]?.countryCertifiedData?.reduce((acc, current) => acc + current.totalValue, 0)
        return {
          ...ele,
          grandTotalForAntwerpTextColor: determineCellClass(ele.grandTotalForAntwerp, updatedData[index + 1]?.grandTotalForAntwerp),
          grandTotalForBarcelonaTextColor: determineCellClass(ele.grandTotalForBarcelona, updatedData[index + 1]?.grandTotalForBarcelona),
          grandTotalForHamburgTextColor: determineCellClass(ele.grandTotalForHamburg, updatedData[index + 1]?.grandTotalForHamburg),
          grandTotalForHoustonTextColor: determineCellClass(ele.grandTotalForHouston, updatedData[index + 1]?.grandTotalForHouston),
          grandTotalForMiamiTextColor: determineCellClass(ele.grandTotalForMiami, updatedData[index + 1]?.grandTotalForMiami),
          grandTotalForNewOrleansTextColor: determineCellClass(ele.grandTotalForNewOrleans, updatedData[index + 1]?.grandTotalForNewOrleans),
          grandTotalForNewYorkTextColor: determineCellClass(ele.grandTotalForNewYork, updatedData[index + 1]?.grandTotalForNewYork),
          grandTotalForVirginiaTextColor: determineCellClass(ele.grandTotalForVirginia, updatedData[index + 1]?.grandTotalForVirginia),
          grandTotalForCompareToPreDayTextColor: getTextColor(ele.grandTotalForCompareToPreDay),
          grandTotalForTotal: currentGrandTotalForTotal,
          grandTotalForTotalTextColor: determineCellClass(currentGrandTotalForTotal, nextGrandTotalForTotalTotal)
        }
      }
    })

    setModifiedData(updatedFinalArray)
  }

  const formatArabicaReportValue = (value) => {
    const updatedValue = swapData === 'Metric Tons' ? Math.round(value)?.toLocaleString('en-US') : convertMTToBags(value)?.toLocaleString('en-US')

    return updatedValue
  }

  const formatRobustaReportValue = (value) => {
    const updatedData = swapData === 'Metric Tons' ? value?.toLocaleString('en-US') : (value / 10)?.toLocaleString('en-US')

    return updatedData
  }

  return (
    <div className="reports-section" data-testid="country-stock-report-main-container" style={checkForUserAccess(menuLock)}>
      <div className="header-container-wrapper" data-testid="country-stock-report-header-wrapper">
        <div className="header-container" data-testid="country-stock-report-header-container">
          <div className="market-selector" onClick={() => handleMarketNameChange()} data-testid="contract-label-container">
            {selectedMarket}
            <div className="arrow-toggle" data-testid="contract-arrow-toggle-container">
              <i className="pi pi-angle-up arrow-up" data-testid="arrow-up-icon" />
              <i className="pi pi-angle-down arrow-down" data-testid="arrow-down-icon" />
            </div>
          </div>
          <div className="content-title">{t('ALL_COUNTRY_CERTIFIED_STOCK')}</div>
          <div className="swap-content-wrapper" onClick={toggleSwapOption} data-testid="swap-content-wrapper">
            {swapData}
            <div className="swap-button" data-testid="quantity-swap-button">
              <i className="pi pi-angle-up upper-arrow" data-testid="quantity-arrow-up-icon" />
              <i className="pi pi-angle-down down-arrow" data-testid="quantity-arrow-down-icon" />
            </div>
          </div>
        </div>
      </div>
      <div className="report-wrapper" data-testid="country-stock-report-table-wrapper">
        <>
          {selectedMarket === 'Robusta' &&
            modifiedData?.map(({ date, countryCertifiedData, grandTotalForValid, grandTotalForCompareToPreDay, grandTotalForNonTenderable, grandTotalForSuspend, grandTotalForValidTextColor, grandTotalForCompareToPreDayTextColor }, index) => (
              <table key={index}>
                <thead>
                  <tr className="date-header" data-testid="date-header-container">
                    <th colSpan={6}>
                      <div data-testid="country-stock-report-container">{date}</div>
                    </th>
                  </tr>

                  <tr className="table-header-row" data-testid="table-header-row-container">
                    <th className="serial-number-column">{t('SL_NO')} </th>
                    <th className="origin-column">{t('ORIGIN')} </th>
                    <th className="validity-column">{t('VALID')} </th>
                    <th className="compare-previous-day-column">{t('COMPARE_TO_PRE_DAY')} </th>
                    <th className="non-tenderable-column">{t('NON_TENDERABLE')} </th>
                    <th className="suspended-column">{t('SUSPENDED')} </th>
                  </tr>
                </thead>
                <tbody>
                  {countryCertifiedData.map(({ slNo, origin, valid, compareToPreDay, NonTenderable, suspend, validTextColor, compareToPreDayTextColor, flagUrl, countryName }, rowIndex) => (
                    <tr key={rowIndex}>
                      <td data-testid="country-stock-report-sl-no-container">{slNo}</td>
                      <td>
                        <div className="origin-wrapper-for-robusta" data-testid="origin-wrapper-for-robusta-container">
                          <img src={flagUrl} alt="flag" className="flag-icon" title="country-flag" />
                          <div className="origin-name" data-testid="origin-name-container">
                            {origin}
                          </div>
                          <div className="country-name" data-testid="country-name-container">
                            - {countryName}
                          </div>
                        </div>
                      </td>
                      <td className={validTextColor} data-testid="country-stock-report-valid-container">
                        {formatRobustaReportValue(valid)}
                      </td>
                      <td className={compareToPreDayTextColor} data-testid="country-stock-report-compare-day-container">
                        {formatRobustaReportValue(compareToPreDay)}
                      </td>
                      <td data-testid="country-stock-report-non-tenderable-container">{formatRobustaReportValue(NonTenderable)}</td>
                      <td>{formatRobustaReportValue(suspend)}</td>
                    </tr>
                  ))}
                  <tr className="total-tr" data-testid="country-stock-report-total-tr-container">
                    <td></td>
                    <td className="totals-label">{t('TOTAL_VALUE')}</td>
                    <td className={grandTotalForValidTextColor} data-testid="grand-total-for-valid-container">
                      {formatRobustaReportValue(grandTotalForValid)}
                    </td>
                    <td className={grandTotalForCompareToPreDayTextColor} data-testid="total-for-compare-to-pre-day-container">
                      {formatRobustaReportValue(grandTotalForCompareToPreDay)}
                    </td>
                    <td data-testid="total-for-non-tenderable-container">{formatRobustaReportValue(grandTotalForNonTenderable)}</td>
                    <td data-testid="total-for-suspended-container">{formatRobustaReportValue(grandTotalForSuspend)}</td>
                  </tr>
                </tbody>
              </table>
            ))}

          {selectedMarket === 'Arabica' &&
            modifiedData?.map(({ countryCertifiedData, date, grandTotalForAntwerp, grandTotalForBarcelona, grandTotalForHamburg, grandTotalForHouston, grandTotalForMiami, grandTotalForNewOrleans, grandTotalForNewYork, grandTotalForVirginia, grandTotalForCompareToPreDay, grandTotalForAntwerpTextColor, grandTotalForBarcelonaTextColor, grandTotalForHamburgTextColor, grandTotalForHoustonTextColor, grandTotalForMiamiTextColor, grandTotalForTotalTextColor, grandTotalForNewOrleansTextColor, grandTotalForNewYorkTextColor, grandTotalForVirginiaTextColor, grandTotalForCompareToPreDayTextColor, grandTotalForTotal }, index) => (
              <table key={index}>
                <thead>
                  <tr className="date-header" data-testid="arabica-date-header-container">
                    <th colSpan={12}>
                      <div data-testid="arabica-date-container">{date}</div>
                    </th>
                  </tr>
                  <tr className="arabica-tr-sticky" data-testid="arabica-tr-sticky-container">
                    <th colSpan={12} className="warehouse-header">
                      {t('WAREHOUSES_TITLE')}
                    </th>
                  </tr>
                  <tr className="table-header-row-arabica" data-testid="table-header-row-arabica-container">
                    <th className="index-column">{t('SL_NO')}</th>
                    <th className="country-of-origin-column">{t('ORIGIN')}</th>
                    {arabicaWarehouseColumns.map((el) => (
                      <th className="port-name-column" data-testid="arabica-port-name-container">
                        <div className="flag-country-container" data-testid="arabica-flag-and-warehouse-name-container">
                          <img src={el.flagsUrl} alt="country-flag" title="arabica-flag-image-tag" />
                          <div className="warehouse-name" data-testid="arabica-warehouse-name-container">
                            {t(el.warehouseName)}
                          </div>
                        </div>
                        <div>
                          <div className="country-name" data-testid="arabica-country-name-container">
                            ({el.countryName})
                          </div>
                        </div>
                      </th>
                    ))}
                    <th className="total-column">{t('TOTAL_VALUE')}</th>
                    <th className="previous-day-comparison-column">{t('COMPARE_TO_PRE_DAY')}</th>
                  </tr>
                </thead>
                <tbody>
                  {countryCertifiedData.map(({ slNo, origin, antwerp, barcelona, hamburg, houston, miami, newOrleans, newYork, virginia, compareToPreDay, antwerpTextColor, barcelonaTextColor, hamburgTextColor, houstonTextColor, miamiTextColor, newOrleansTextColor, newYorkTextColor, virginiaTextColor, compareToPreDayTextColor, totalValue, totalTextColor, flagUrl }, rowIndex) => (
                    <tr key={rowIndex}>
                      <td data-testid="arabica-table-sl-no-container">{slNo}</td>
                      <td>
                        <div className="origin-wrapper-for-arabica" data-testid="arabica-origin-container">
                          <img src={flagUrl} alt="flag" className="flag-icon" title="origin-arabica-flag" />
                          <div className="origin-name" data-testid="origin-arabica-name">
                            {origin}
                          </div>
                        </div>
                      </td>
                      <td className={antwerpTextColor} data-testid="arabica-antwerp-container">
                        {formatArabicaReportValue(antwerp)}
                      </td>
                      <td className={barcelonaTextColor} data-testid="arabica-barcelona-container">
                        {formatArabicaReportValue(barcelona)}
                      </td>
                      <td className={hamburgTextColor} data-testid="arabica-hamburg-container">
                        {formatArabicaReportValue(hamburg)}
                      </td>
                      <td className={houstonTextColor} data-testid="arabica-houston-container">
                        {formatArabicaReportValue(houston)}
                      </td>
                      <td className={miamiTextColor} data-testid="arabica-miami-container">
                        {formatArabicaReportValue(miami)}
                      </td>
                      <td className={newOrleansTextColor} data-testid="arabica-new-orleans-container">
                        {formatArabicaReportValue(newOrleans)}
                      </td>
                      <td className={newYorkTextColor} data-testid="arabica-new-York-container">
                        {formatArabicaReportValue(newYork)}
                      </td>
                      <td className={virginiaTextColor} data-testid="arabica-virginia-container">
                        {formatArabicaReportValue(virginia)}
                      </td>
                      <td className={totalTextColor} data-testid="arabica-total-value-container">
                        {formatArabicaReportValue(totalValue)}
                      </td>
                      <td className={compareToPreDayTextColor} data-testid="arabica-compareToPreDay-container">
                        {formatArabicaReportValue(compareToPreDay)}
                      </td>
                    </tr>
                  ))}

                  <tr className="total-tr" data-testid="total-value-row-container">
                    <td></td>
                    <td>{t('TOTAL_VALUE')}</td>
                    <td className={grandTotalForAntwerpTextColor} data-testid="arabica-grand-total-for-antwerp-container">
                      {formatArabicaReportValue(grandTotalForAntwerp)}
                    </td>
                    <td className={grandTotalForBarcelonaTextColor} data-testid="arabica-grand-total-for-barcelona-container">
                      {formatArabicaReportValue(grandTotalForBarcelona)}
                    </td>
                    <td className={grandTotalForHamburgTextColor} data-testid="arabica-grand-total-for-hamburg-container">
                      {formatArabicaReportValue(grandTotalForHamburg)}
                    </td>
                    <td className={grandTotalForHoustonTextColor} data-testid="arabica-grand-total-for-houston-container">
                      {formatArabicaReportValue(grandTotalForHouston)}
                    </td>
                    <td className={grandTotalForMiamiTextColor} data-testid="arabica-grand-total-for-miami-container">
                      {formatArabicaReportValue(grandTotalForMiami)}
                    </td>
                    <td className={grandTotalForNewOrleansTextColor} data-testid="arabica-grand-total-for-new-orleans-container">
                      {formatArabicaReportValue(grandTotalForNewOrleans)}
                    </td>
                    <td className={grandTotalForNewYorkTextColor} data-testid="arabica-grand-total-for-new-york-container">
                      {formatArabicaReportValue(grandTotalForNewYork)}
                    </td>
                    <td className={grandTotalForVirginiaTextColor} data-testid="arabica-grand-total-for-virginia-container">
                      {formatArabicaReportValue(grandTotalForVirginia)}
                    </td>
                    <td className={grandTotalForTotalTextColor} data-testid="arabica-grand-total-for-total-container">
                      {formatArabicaReportValue(grandTotalForTotal)}
                    </td>
                    <td className={grandTotalForCompareToPreDayTextColor} data-testid="arabica-grand-total-for-compare-to-pre-day-container">
                      {formatArabicaReportValue(grandTotalForCompareToPreDay)}
                    </td>
                  </tr>
                </tbody>
              </table>
            ))}
        </>
      </div>
    </div>
  )
}

export default CountryCertifiedStocks
