import './styles.scss'
import React, { useEffect, useState, useContext } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { Divider } from 'primereact/divider'
import DropdownComponent from 'Components/DropdownComponent'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import { CoffeeWebContext } from 'Context/CoffeeWebContext'
import { commonMethods } from 'Utils/commonMethods'
import { getLocalUserDetails } from 'Utils/LocalStorage_Handler'

export default function USDACoffeeReports() {
  const { setLoading } = useContext(CoffeeWebContext)
  const { t } = useTranslation()
  const { checkForUserAccess } = commonMethods
  const userDetails = getLocalUserDetails()
  const location = useLocation()
  const fullPath = location.pathname
  const pathSegments = fullPath.split('/')
  const desiredPath = pathSegments[2] || ''

  let menuLock

  if (userDetails && userDetails.menus) {
    const menu = userDetails.menus.find(({ menuUrl }) => menuUrl === desiredPath)

    if (menu) {
      menuLock = menu.menulock
    }
  }

  const [selectedCountry, setSelectedCountry] = useState({ id: 'all', name: 'All Countries', flagsUrl: 'https://www.usda.gov/themes/usda/img/usda-symbol.svg' })
  const [getUSDAData, setGetUSDAData] = useState([])
  const [countriesList, setCountriesList] = useState([])
  const [expandedReports, setExpandedReports] = useState({})
  const [currentItem, setCurrentItem] = useState(null)

  useEffect(() => {
    getAllCountries()
  }, [getUSDAData, setLoading])

  useEffect(() => {
    getUSDACoffeeReport()
  }, [setLoading])

  const getUSDACoffeeReport = async () => {
    setLoading(true)
    try {
      const response = await apiAdapterCoffeeWeb.getUSDACoffeeReport()

      setGetUSDAData(response?.data?.returnLst)
    } catch (error) {
      console.log(error.message)
    } finally {
      setLoading(false)
    }
  }

  const getAllCountries = async () => {
    try {
      const response = await apiAdapterCoffeeWeb.getAllCountries()

      if (getUSDAData.length > 0) {
        const countryIds = getUSDAData.map((item) => item.countryId)
        const filteredCountries = response?.data.filter((element) => countryIds.includes(element.id))

        const allCountriesOption = { id: 'all', nicename: 'All Countries', flagsUrl: 'https://www.usda.gov/themes/usda/img/usda-symbol.svg' }

        setCurrentItem(allCountriesOption)
        setCountriesList([allCountriesOption, ...filteredCountries])
      }
    } catch (error) {
      console.log(error.message)
    } finally {
      setLoading(false)
    }
  }

  const handleDropDownChange = (event) => {
    setSelectedCountry(event)
  }

  const toggleExpand = (id) => {
    setExpandedReports((prevState) => ({
      ...prevState,
      [id]: !prevState[id]
    }))
  }

  const filteredUSDAData = selectedCountry.id === 'all' ? getUSDAData : getUSDAData.filter((report) => report.countryId === selectedCountry.id)

  const getContentToShow = (isExpanded, content) => {
    if (isExpanded) {
      return content
    }

    return content.length > 500 ? `${content.substring(0, 300)}...` : content
  }

  return (
    <div className="usda-coffee-report-container" style={checkForUserAccess(menuLock)}>
      <div className="usda-heading-container">
        <div className="usda-country-dropdown">
          <DropdownComponent currentItem={currentItem} setCurrentItem={setCurrentItem} countryList={countriesList} handleSelectCountry={handleDropDownChange} />
        </div>
        <div className="usda-heading">
          <p className="usda-heading-content">{t('USDA_COFFEE_REPORTS')}</p>
        </div>
      </div>
      <div className="usda-map-data">
        {filteredUSDAData?.map((element) => {
          const isExpanded = expandedReports[element.idReport]
          const contentToShow = getContentToShow(isExpanded, element.content)

          return (
            <div key={element.idReport}>
              <div className="usda-date-container">
                <p className="usda-date">{element?.isUpcoming ? 'Upcoming Report' : `Date: ${moment(element.publishedDate).format('MMMM Do YYYY')}`}</p>
                <p className="usda-tags">{element?.tags}</p>
              </div>

              {element?.isUpcoming ? (
                <div className="usda-upcoming-container">
                  {t('WILL_BE_PUBLISHED_ON')} {moment(element.publishedDate).format('MMMM Do YYYY')}
                </div>
              ) : (
                <div className="usda-report-highlights-container">
                  <p className="reports-title">{element.title}</p>
                  <div className="report-content">
                    <span>{contentToShow}</span>
                    {element.content.length > 500 && (
                      <span>
                        <a href="#" onClick={() => toggleExpand(element.idReport)} className="read-more">
                          {isExpanded ? t('READ_LESS') : t('READ_MORE')}
                        </a>
                      </span>
                    )}
                  </div>
                  <div className="link-report-container">
                    <span>{t('LINK_TO_REPORT')}</span>
                    <a href={element.reportLink} target="_blank" rel="noreferrer" className="report-link">
                      {element.tags}
                    </a>
                  </div>
                </div>
              )}
              <Divider className="divider-style" />
            </div>
          )
        })}
      </div>
    </div>
  )
}
